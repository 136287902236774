/*
import React from "react";
import { stockData } from "../data/companies";
*/

import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';

class CompanyCharts extends React.Component {
    // static demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';
    constructor(props) {
        super(props);
        console.log("props = "+JSON.stringify(props))
        this.state = {
            companyData: ''
        };
    }

    async getData(ticker) {
        return fetch(`${ticker}.json`
            ,{
                headers : {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            return myJson;
        });
    }

    async componentDidMount() {
        console.log(`GOT TICKER `+this.props.location.state.ticker);
        const companyData = await this.getData(this.props.location.state.ticker)
        this.setState({companyData: companyData});
    }

    render() {
        return (
            <div className="ui container">
                <ResponsiveContainer width={'100%'} height={500}>
                    <BarChart
                        width={500}
                        height={300}
                        data={this.state.companyData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#8884d8" />
                        <Bar dataKey="uv" fill="#82ca9d" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}


/*
class CompanyCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    rowSelect(data) {
        alert(`row selected `+JSON.stringify(data))
    }

    render() {
        return <>
            <div className="stock-container">
                <table className="ui celled table" id="remindersList">
                    <thead>
                    <tr>
                        <th>Company</th>
                        <th>Ticker</th>
                        <th>StockPrice</th>
                        <th>Time Elapsed</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stockData.map((data, key) => {
                        return (
                            <tr key={key} onClick={() => this.rowSelect(data)}>
                                <td data-label>{data.company}</td>
                                <td data-label>{data.ticker}</td>
                                <td data-label>{data.stockPrice}</td>
                                <td data-label>{data.timeElapsed}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </>;
    }
};
*/

export default CompanyCharts;

