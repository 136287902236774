/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://a6r24qheiza6rp475ipitlcsmy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-lxsdffv5pvgs3e2a5ui4thckf4",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeProxy",
            "endpoint": "https://p4t0o2fxc4.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:18caf535-aca8-4afe-9b79-67e95ae2683f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_6zsiiqfTB",
    "aws_user_pools_web_client_id": "27ti52gr3cmk4aftabuov9qt1b",
    "oauth": {}
};


export default awsmobile;
