import React from "react";
import { stockData } from "../data/companies";
import {withRouter} from "react-router-dom";

class Stocks extends React.Component {
    constructor(props) {
        super(props);
        console.log("props = "+JSON.stringify(props))
/*        this.state = {
        };*/
    }

    rowSelect(data) {
        this.props.history.push('/charts', { ticker: data.ticker});
    }

    render() {
        return <>
            <div className="stock-container">
                <table className="ui celled table" id="remindersList">
                    <thead>
                    <tr>
                        <th>Company</th>
                        <th>Ticker</th>
                        <th>StockPrice</th>
                        <th>Time Elapsed</th>
                    </tr>
                    </thead>
                    <tbody>
                    {stockData.map((data, key) => {
                        return (
                            <tr key={key} onClick={() => this.rowSelect(data)}>
                                <td data-label>{data.company}</td>
                                <td data-label>{data.ticker}</td>
                                <td data-label>{data.stockPrice}</td>
                                <td data-label>{data.timeElapsed}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        </>;
    }
};

export default withRouter(Stocks);
