import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect, withRouter
} from 'react-router-dom';
import { useAuth } from './hooks';
import {SignIn, Guest, Home, Profile, Success, Canceled, Plans} from "./pages";
import Header from "./components/Header";
import '@stripe/stripe-js';
import Stocks from "./components/CompaniesList";
import CompanyCharts from "./components/CompanyCharts";

const App = () => {
    const { isSignedIn=false } = useAuth();

    return (
        <Router>
            <div className="App">
                <Header />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/home">
                        {isSignedIn ? <Home /> : <Redirect to="/signin" />}
                    </Route>
                    <Route path="/profile">
                        {isSignedIn ? <Profile /> : <Redirect to="/signin" />}
                    </Route>
                    <Route path="/plans">
                        {isSignedIn ? <Plans /> : <Redirect to="/signin" />}
                    </Route>
                    <Route path="/success">
                        <Success />
                    </Route>
                    <Route path="/canceled">
                        <Canceled />
                    </Route>
                    <Route path="/guest">
                        <Guest />
                    </Route>
                    <Route path="/companies">
                        <Stocks />
                    </Route>
                    <Route path="/charts" component={CompanyCharts}>
                    </Route>
                    <Route path="/signin">
                        {isSignedIn ? <Redirect to="/home" /> : <SignIn />}
                    </Route>

                    {/* default redirect to home page */}
                    {/* <Redirect from="*" to="/" /> */}
                    {/*
                    catch all routing approach mentioned from this article -> https://stackoverflow.com/a/36623117
                    */}
                </Switch>
            </div>
        </Router>
    );
}

export default App;