import { Link, useLocation } from 'react-router-dom';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { useAuth } from '../hooks';

import './Header.css';


const Header = () => {
    const { isSignedIn=false } = useAuth();
    const { pathname } = useLocation();

    return (
        <div className="header">
            <ul>
                <li><Link to="/">Home</Link></li>
                { isSignedIn &&
                <>
                    <li><Link to="/home">Dashboard</Link></li>
                    <li><Link to="/plans">Plans</Link></li>
                    <li><Link to="/profile">Profile</Link></li>
                    <li><AmplifySignOut /></li>
                </>
                }
                { !isSignedIn &&
                <>
                    <li><Link to="/guest">Preview</Link></li>
                    { pathname !== '/signin' &&
                    <li><Link to="/signin">Sign in</Link></li>
                    }
                </>
                }
            </ul>
        </div>
    )
}

export default Header;