import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from "./hooks";

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';


// https://github.com/aws-amplify/amplify-js/issues/3326#issuecomment-577010244
const options = Object.assign({}, awsconfig, {
    API: {
        graphql_headers: async () => {
            const session = await Auth.currentSession();
            return {
                Authorization: session.getIdToken().getJwtToken(),
            };
        },
    },
});

Amplify.configure(options);


ReactDOM.render(
  <React.StrictMode>
      <ProvideAuth>
          <App />
      </ProvideAuth>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
