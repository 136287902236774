import React, {Button} from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {API, graphqlOperation, Auth} from 'aws-amplify';
import * as mutations from "../graphql/mutations";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_WqWPBBYdga0xCMwqJhRLljx200clgDUUfK');

class PlansComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            plans: []
        };
    }

    async componentDidMount() {
        console.log('about to fetch all plans ');
        const allPlans = await API.graphql(graphqlOperation(mutations.callStripe, {
            input: {
                action: "GET_PLANS"
            }
        }));

        const session = await Auth.currentSession();
        console.log(`email : ` + JSON.stringify(session.getIdToken().payload.email))

        const allPlansJson = JSON.parse(allPlans.data.callStripe);
        console.log('allPlans '+JSON.stringify(allPlansJson.data));
        this.setState({plans: allPlansJson.data});
    }

    async handlePlanSelect(plan) {
        const stripe = await stripePromise;

        //const stripe = useStripe();
        //const stripe = Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
        console.log(`selected plan is ${JSON.stringify(plan.id)}`);
/*        API.post("stripeProxy", "/stripe/proxy", {
            body: {
                action: "CREATE_CHECKOUT_SESSION",
                priceId: `${plan.id}`
            }
        })*/
        API.graphql(graphqlOperation(mutations.callStripe, {
            input: {
                action: "CREATE_CHECKOUT_SESSION",
                priceId: `${plan.id}`
            }
        })).then((data) => {
            // Call Stripe.js method to redirect to the new Checkout page
            const sessionId = JSON.parse(data.data.callStripe).sessionId
            stripe
                .redirectToCheckout({
                    sessionId
                })
                .then(this.handleResult);
        });
    }

    handleResult(data){
        console.log(`Handling result ${data}`);
    }

    render () {
        const { plans } = this.state;

        return (<div className="home message">
            Plans page goes here...

            <div className='ui container codepen-margin'>
                <div className="ui grid">
                    { plans.map(plan => (
                        <div className="five wide column" key={plan.id}>
                            <div className="ui raised segments">
                                <div className="ui center aligned secondary segment">
                                    <div className="ui statistic">
                                        <div className="value">
                                            €{plan.amount / 100}
                                        </div>
                                        <div className="label">
                                            per {plan.interval}
                                        </div>
                                    </div>
                                </div>
                                <div className="ui center aligned segment">
                                    <p> - Premium Feature One </p>
                                </div>
                                <div className="ui center aligned segment">
                                    <p> - Another great feature </p>
                                </div>
                            </div>
                            <div>
                                <input key={plan} className="ui green fluid button" type="button" value="Select" onClick={() => this.handlePlanSelect(plan)}></input>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>);
    }
}

export default PlansComponent;