import { useAuth } from '../hooks';
import {Link} from "react-router-dom";
import SuccessComponent from "../components/SuccessComponent";
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

const Success = () => {
    const { isSignedIn=false } = useAuth();
    const queryString = qs.parse(useLocation().search);

    return (
        <div className="Success message">
            Success page goes here...
            <SuccessComponent sessionId={queryString.session_id}/>
            <ul>
                <li><Link to="/">Landing Page</Link></li>
                { isSignedIn &&
                <>
                    <li><Link to="/home">Dashboard</Link></li>
                </>
                }
            </ul>
        </div>
    );
}

export default Success;