import React from 'react';
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import { API, graphqlOperation } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';


class listComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reminderName: '',
            reminderDate: '',
            reminderFrequency: ''
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleChangeFrequency = this.handleChangeFrequency.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.loadList();
    }

    countReminders(data) {
        // TODO basic count, may want to count by actual emails sent
        let dailyCount = 0;
        let weeklyCount = 0;
        let monthlyCount = 0;
        let yearlyCount = 0;
        let onceOffCount = 0;

        for (const reminderInstance in data) {
            if("daily" == reminderInstance.frequency) {
                dailyCount++;
            } else if ("weekly" == reminderInstance.frequency) {
                weeklyCount++;
            } else if("monthly" == reminderInstance.frequency) {
                monthlyCount++;
            } else if("yearly" == reminderInstance.frequency) {
                yearlyCount++;
            } else {
                onceOffCount++;
            }
        }
        let count = onceOffCount + dailyCount + weeklyCount + monthlyCount + yearlyCount;
        console.log(`count stats for reminder:  ${JSON.stringify(data.name)}, total count: ${count}, 
                onceOff count: ${onceOffCount}, daily count: ${dailyCount}, weekly count: ${weeklyCount},
                monthly count: ${monthlyCount}, yearly count: ${yearlyCount}.`);

        return count;
    }

    async loadList() {
        const allReminders = await API.graphql({ query: list_query });
        const reminderCount = this.countReminders(allReminders.data.listReminders.items)
        const showUpgrade = reminderCount >= 3
        console.log(`in loadlist... showUpgrade is ${showUpgrade} and reminderCount is `+reminderCount);

        this.setState({reminderList: allReminders});
        this.setState({hideUpgrade: showUpgrade});
    }

    create_reminder(name, date, frequency){
        return "mutation createReminder {\n" +
            "  createReminder(input: {\n" +
            "    name: \""+name +"\"\n" +
            "    type: email\n" +
            "    frequency: "+frequency+"\n" +
            "    date: \""+date+"\"\n" +
            "    originalDate: \""+date+"\"\n" +
            "  }) {\n" +
            "    id\n" +
            "  }\n" +
            "}";
    }

    handleChangeName(event) {
        this.setState({reminderName: event.target.value});
    }

    handleChangeDate(event) {
        this.setState({reminderDate: event});
    }

    handleChangeFrequency(event) {
        this.setState({reminderFrequency: event});
    }

    handleSubmit(event) {
        console.log("reminderFrequency is  "+ this.state.reminderFrequency.value);
        const date = moment(this.state.reminderDate).format("YYYY-MM-DDThh:mm:ss.sssZ")

        API.graphql({ query: this.create_reminder(this.state.reminderName, date, this.state.reminderFrequency.value) }).then(() => {
            this.loadList();
        });

        event.preventDefault();

    }

    async deleteReminder(reminder, event) {
        console.log('deleting reminder with id '+reminder.id)
        const deleteMutation = `mutation delete {deleteReminder(input: {id: "${reminder.id}"}) {id}}`
        await API.graphql({ query: deleteMutation })
        await this.loadList() // TODO this maybe over kill
    }

    render() {
        const { reminderList, hideUpgrade } = this.state;

        const items = []
        if(reminderList) {
            let listItems = reminderList.data.listReminders.items;
            for (let i=0; i<listItems.length; i++) {
                items.push(
                    <tr>
                        <td data-label>{listItems[i].name}</td>
                        <td data-label>{listItems[i].date}</td>
                        <td data-label>{listItems[i].timeHour}</td>
                        <td>
                            <button onClick={(e) => this.deleteReminder(listItems[i])}>
                                X
                            </button>
                        </td>
                    </tr>)
            }
        }
        const options = [
            { value: 'onceOff', label: 'Once Off' },
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
            { value: 'yearly', label: 'Yearly' }
        ];
        const defaultOption = options[0];
        console.log('HIDE is '+hideUpgrade)
        return <div className="ui container">
            { !hideUpgrade ? (
                <form className="ui form" id="addReminderForm" >
                    {/*onSubmit={this.handleSubmit} >
                    */}<div className="inline fields" >
                        <div className="eight wide field">
                            <label>Reminder</label>
                            <input type="text" id="name" value={this.state.reminderName} onChange={this.handleChangeName} placeholder="Name" />
                        </div>
                        <div className="seven wide field">
                            <DatePicker
                                selected={this.state.reminderDate}
                                onChange={this.handleChangeDate} //only when value has changed
                                showTimeSelect
                            />
                       {/*     <input type="text" id="date" value={this.state.reminderDate} onChange={this.handleChangeDate} placeholder="Date" />
                       */} </div>
                        <div className="five wide field">
                            <Dropdown id="frequency" options={options} onChange={this.handleChangeFrequency} value={this.state.reminderFrequency} placeholder="Frequency" />;
                        </div>
                        <div className="right floated field">
                            <button onClick={this.handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="inline fields" >
                    <div className="right floated field">
                        <Link to="/plans" className="btn btn-primary">Upgrade</Link>
                    </div>
                </div>
            )}

            <table className="ui celled table" id="remindersList">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    { items }
                </tbody>
            </table>

        </div>;
    }
}

const list_query = 'query q {\n' +
    '  listReminders {\n' +
    '    items {\n' +
    '      id\n' +
    '      name\n' +
    '      date\n' +
    '      timeHour\n' +
    '    }\n' +
    '  }\n' +
    '}';

export default withAuthenticator(listComponent, true);
