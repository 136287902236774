import React from "react";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../graphql/mutations";

class SuccessComponent extends React.Component {
    constructor(props) {
        super(props);
        /*this.state = {
        };*/
    }

    /*
    *
    * {
          "id": "cs_test_a1AXrkSYCPvqSoGDu15YuVaQXcPYWK0Lp48ukW34OZLMxkQsAJDbSkbP1c",
          "object": "checkout.session",
          "after_expiration": null,
          "allow_promotion_codes": null,
          "amount_subtotal": 400,
          "amount_total": 400,
          "automatic_tax": {
            "enabled": false,
            "status": null
          },
          "billing_address_collection": null,
          "cancel_url": "http://localhost:3001/canceled",
          "client_reference_id": null,
          "consent": null,
          "consent_collection": null,
          "currency": "eur",
          "customer": "cus_JB1p4v90Ov2RtM",
          "customer_creation": null,
          "customer_details": {
            "email": "test@test.com",
            "phone": null,
            "tax_exempt": "none",
            "tax_ids": []
          },
          "customer_email": null,
          "expires_at": 1646688128,
          "livemode": false,
          "locale": null,
          "metadata": {},
          "mode": "subscription",
          "payment_intent": null,
          "payment_link": null,
          "payment_method_options": null,
          "payment_method_types": [
            "card"
          ],
          "payment_status": "paid",
          "phone_number_collection": {
            "enabled": false
          },
          "recovered_from": null,
          "setup_intent": null,
          "shipping": null,
          "shipping_address_collection": null,
          "shipping_options": [],
          "shipping_rate": null,
          "status": "complete",
          "submit_type": null,
          "subscription": "sub_1KaRdcLPfSzgEMsIMy8qFbbi",
          "success_url": "http://localhost:3001/success?session_id={CHECKOUT_SESSION_ID}",
          "total_details": {
            "amount_discount": 0,
            "amount_shipping": 0,
            "amount_tax": 0
          },
          "url": null
        }}
    *
    * */
    async componentDidMount() {
        console.log(`get session details for session id ${this.props.sessionId}`);
        const sessionData = await API.graphql(graphqlOperation(mutations.callStripe, {
            input: {
                action: "GET_SESSION",
                stripeSessionId: this.props.sessionId
            }
        }));

        const sessionDataJson = JSON.parse(sessionData.data.callStripe);
        console.log('sessionDataJson '+JSON.stringify(sessionDataJson));
        this.setState({stripeSession: sessionDataJson});
    }

    render() {
        return <div/>;
    }

}

export default SuccessComponent;