import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';

const SignIn = () => {
    return (
        <AmplifyAuthenticator>
            <AmplifySignIn
                slot='sign-in'
            ></AmplifySignIn>
        </AmplifyAuthenticator>
    );
}

export default SignIn;