import { useAuth } from '../hooks';
import {Link} from "react-router-dom";

const Canceled = () => {
    const { isSignedIn=false } = useAuth();
    
    return (
        <div className="Canceled message">
            Canceled page goes here...

            <ul>
                <li><Link to="/">Landing Page</Link></li>
                { isSignedIn &&
                <>
                    <li><Link to="/home">Dashboard</Link></li>
                </>
                }
            </ul>
        </div>
    );
}

export default Canceled;